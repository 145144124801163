import { Icon, Text } from 'react-native-elements';
import { Image, SafeAreaView, StatusBar,TouchableOpacity, View } from 'react-native';
import {
    SafeAreaProvider,
    useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isAndroid } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';


const Header = ({ step, of, screenName, screenNavigation }) => {
    const { top } = useSafeAreaInsets();
    const { canGoBack, goBack } = useNavigation();
    const navigation = useNavigation();

    return (
                <View
                    style={{
                        flexDirection: 'row',
                        marginBottom: 20,
                        alignItems: 'center',  
                        width: '100%',
                    }}
                >
                    <View style={{ width: 50 }}>
                        {screenName == "signupScreen" ?
                            <></>
                            :
                            canGoBack() && (
                                <TouchableOpacity
                                    onPress={() => goBack()}
                                    style={{ opacity: canGoBack() ? 1 : 0 }}
                                >
                                    <Icon
                                        name="chevron-left"
                                        color="#00008E"
                                        size={35}
                                    />
                                </TouchableOpacity>
                            )
                        }
                        {screenName == "personalInfo" && (
                            <TouchableOpacity
                                onPress={() => navigation.navigate('Auth.Signup')}
                                style={{ opacity: 1 }}
                            >
                                <Icon
                                    name="chevron-left"
                                    color="#00008E"
                                    size={35}
                                />
                            </TouchableOpacity>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: screenName == "verifyAccount" ? 25 : 50,
                            justifyContent: 'center',
                        
                        }}
                    >
                        {!step && (
                            <>
                                <Image
                                    source={require('../../../../assets/images/registrationpage_logo.png')}
                                    style={{ width: 128, height: 34 }}
                                />
                            </>
                        )}
                        {step && (
                            <View
                                style={{
                                    alignItems: 'center',
                                    marginLeft: 10,
                                    
                                }}
                            >
                                <Text
                                    h2
                                    style={{
                                        fontFamily: 'DMSans_700Bold',
                                    }}
                                >
                                    {i18n.t('common.registration')}
                                </Text>
                                <Text
                                    style={{
                                        color: theme.colors.textHeading,
                                        fontSize: 16,
                                        fontFamily: 'DMSans_400Regular',
                                        letterSpacing: 0.15,
                                    }}
                                >
                                    {i18n.t('common.stepOf', { step, of })}
                                </Text>
                            </View>
                        )}
                    </View>
                    <View >
                        {step && screenName == "verifyAccount" && (
                            <TouchableOpacity onPress={() => screenNavigation.push('Onboarding.AccountSelection')}>
                                <Text style={{
                                    textDecorationLine: 'underline',
                                    color: '#00008E',
                                    fontSize: 16,
                                    lineHeight: 24,
                                    letterSpacing: 0.4,
                                    fontFamily: 'DMSans_700Bold',
                                    marginRight: 25
                                }}>
                                    {i18n.t('common.skip')}
                                </Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
    );
};

Header.propTypes = {
    step: PropTypes.number,
    of: PropTypes.number,
    screenName: PropTypes.string
};

export const OnboardingPageLayout = ({
    title,
    subtitle,
    children,
    step,
    of,
    screenName,
    screenNavigation
}) => (
    <SafeAreaView style={{  paddingTop: StatusBar.currentHeight}}>
        <Header step={step} of={of} screenName={screenName} screenNavigation={screenNavigation} />
        <KeyboardAwareScrollView>
            <SafeAreaProvider
                style={{
                    padding: 25,
                    paddingBottom: 50,
                    display: 'flex',
                    marginBottom: 50,
                    // marginTop: -25,
                }}
            >
                <Text
                    h1
                    style={{
                        lineHeight: 42,
                        letterSpacing: 0.25,
                    }}
                >
                    {title}
                </Text>

                {subtitle && (
                    <Text
                        style={{
                            color: '#666',
                            fontSize: 16,
                            fontWeight: '400',
                            marginTop: 20,
                            marginBottom: 20,
                            fontFamily: 'DMSans_400Regular',
                        }}
                    >
                        {subtitle}
                    </Text>
                )}
                {children}
            </SafeAreaProvider>
        </KeyboardAwareScrollView>
    </SafeAreaView>
);

OnboardingPageLayout.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.any,
    step: PropTypes.number,
    of: PropTypes.number,
};
