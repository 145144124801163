import { Button, Text, Image } from 'react-native-elements';
import { useWindowDimensions, View, TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import moment from 'moment';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { isWeb } from '../../../common/utils/platform.utils';
import { MaskedText } from 'react-native-mask-text';

export const ManageTransferListScreen = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const [selectedProductId, setSelectedProductId] = useState(null);

    /* Static Data / Internal transfer api need to be integrated here */
    const data = [{
        "id": "1",
        "account_to_account": "Account to Account",
        "access_status": "ACTIVE",
        "sender_account_number": "100781698815691",
        "receiver_account_number": "100681698815704",
        "account_type": "CHECKING",
        "transfer_type": "Weekly",
        "when": "07 Nov 2022",
        "endOn": "30 Nov 2022",
        "accountTransferAmount": { "balance": 10000 },
        "senderAccount": [
            {
                "balance": 71400,
                "type": "ACCOUNT_BALANCE"
            }
        ],
        "receiverAccount": [
            {
                "balance": 21800,
                "type": "ACCOUNT_BALANCE"
            }
        ]
    },
    {
        "id": "2",
        "account_to_account": "Account to Account",
        "access_status": "ACTIVE",
        "sender_account_number": "100781698815691",
        "receiver_account_number": "100681698815704",
        "account_type": "CHECKING",
        "when": "01 oct 2022",
        "accountTransferAmount": { "balance": 10000 },
        "senderAccount": [
            {
                "balance": 71400,
                "type": "ACCOUNT_BALANCE"
            }
        ],
        "receiverAccount": [
            {
                "balance": 71400,
                "type": "ACCOUNT_BALANCE"
            }
        ]
    }]

    return (
        <ModalLayout>
            {data?.map((item) => (
                <ManageListProduct
                    key={item.id}
                    product={item}
                    selected={item.id === selectedProductId}
                    onSelect={(productId) => {
                        if (selectedProductId == productId) {
                            setSelectedProductId(null);
                        } else {
                            setSelectedProductId(productId);
                        }
                    }}
                />
            ))}
            <View
                style={{
                    marginTop: 15,
                    marginBottom: 15,
                    bottom: 20,
                    left: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                }}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    disabled={!selectedProductId}
                    title={i18n.t('pages.moveMoney.manageTransfer.manageUpcomingList.scheduleTransfer')}
                    onPress={() => navigation.push('MoveMoney.ManageTransferDetail', { data })}
                >
                </Button>
            </View>
        </ModalLayout>
    )
};

const ManageListProduct = ({ product, selected, onSelect }) => {

    return (
        <TouchableOpacity
            onPress={() => onSelect(product.id)}
            activeOpacity={0.5}
            style={!isWeb() ? { ...theme.shadows.lightBlue } : null}
        >
            <LinearGradient
                {...theme.gradients.lightBlue}
                style={{
                    justifyContent: 'center',
                    borderWidth: 2,
                    boxShadow: selected
                        ? '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'
                        : null,
                    borderColor: selected
                        ? theme.colors.phthaloBlue
                        : 'rgba(0,0,0,0.2)',
                    backgroundColor: '#FFFFFF',
                    marginBottom: 10,
                    borderRadius: 10,
                    padding: 10,
                }}
            >
                <Text
                    style={{
                        fontSize: 12,
                        fontFamily: 'DMSans_700Bold',
                        letterSpacing: 0.4,
                        lineHeight: 20,
                        marginBottom: 10
                    }}
                >
                    {product?.account_to_account}
                </Text>
                <View
                    style={{
                        flexDirection: 'row',
                        marginBottom: 10,
                        justifyContent: 'space-between'
                    }}
                >
                    <View style={{ flexDirection: 'row' }}>
                        <Text
                            style={{
                                fontSize: 17,
                                fontFamily: 'DMSans_700Bold',
                                marginRight: 10
                            }}
                        >
                            {product?.account_type}
                        </Text>
                        <MaskedText mask="**** **** **** 9999">
                            {product?.receiver_account_number}
                        </MaskedText>
                    </View>
                    {selected && (
                        <Image
                            source={require('../.././../../assets/images/transfer.png')}
                            style={{ width: 17, height: 17 }}
                        />
                    )}
                </View>
                {product?.transfer_type && <Text
                    style={{
                        fontSize: 12,
                        fontFamily: 'DMSans_500Medium',
                    }}
                >
                    {product?.transfer_type}
                </Text>}
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontSize: 12,
                            fontFamily: 'DMSans_400Regular',
                            lineHeight: 28,
                            letterSpacing: 0.40,
                            marginRight: 5
                        }}
                    >
                        {i18n.t('pages.moveMoney.manageTransfer.manageUpcomingList.nextTransferDate')}
                    </Text>
                    <Text
                        style={{
                            fontSize: 12,
                            fontFamily: 'DMSans_400Regular',
                            lineHeight: 28,
                            letterSpacing: 0.40,
                        }}
                    >
                        {(product?.when)}
                    </Text>
                </View>
                <TextGradient
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        fontSize: 12,
                        fontFamily: 'DMSans_400Regular',
                        letterSpacing: 0.40,
                        lineHeight: 28,
                    }}
                >
                    <TextCurrency
                        value={
                            product?.accountTransferAmount &&
                            product?.accountTransferAmount?.balance / 100
                        }
                    />
                    <Text
                        style={{
                            fontSize: 12,
                            marginLeft: 10,
                        }}
                    >
                        {i18n.t('common.usDollar')}
                    </Text>
                </TextGradient>
            </LinearGradient>
        </TouchableOpacity>
    );
};

ManageListProduct.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
};
