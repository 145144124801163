import { View, useWindowDimensions } from 'react-native';
import { Button, CheckBox, Icon, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Dropdown } from 'react-native-element-dropdown';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { DatePickerNative } from '../home/components/date-picker.component';
import { useToast } from 'react-native-toast-notifications';
import { api } from '../../../App.context';
import { isWeb } from '../../common/utils/platform.utils';
import { WebDatePicker } from '../home/components/web-date-picker.component';
import { CustomHeaderComponent } from '../../navigation/screen-options.component';

var addDays = require('date-fns/addDays')
export const AccountsMoneyTransfer = () => {
    const [externalAccounts, setExternalAccounts] = useState(null);
    const toast = useToast();
    const {
        params: { accounts },
    } = useRoute();

    const fetchExternalAccountsAccounts = () => {
        toast.hideAll();
        api.get('/external_accounts')
            .then((r) => setExternalAccounts(r.external_accounts))
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };
    useEffect(() => fetchExternalAccountsAccounts(), []);

    return (
        <>
            <CustomHeaderComponent maintitle={i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.title')} subtitle={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.subtitle')} />
            <ModalLayout>
                <MakeDepositForm accounts={accounts} externalAccounts={externalAccounts} />
            </ModalLayout >
        </>
    );
};

const MakeDepositForm = ({ accounts, externalAccounts }) => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const [startDate, setStartDate] = useState(addDays(new Date(),1));
    const [endDate, setEndDate] = useState(null);
    const [isRepeatTranscation, setRepeatTranscation] = useState(true);
    const [repeatValue, setRepeatValue] = useState('');
    const [endOnTransferValue, setEndOnTransferValue] = useState('');

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': '',
            'howOften': '',
            'endOn': '',
            'endOn_NumTransfer': ''
        }
    });

    const recurringData = [
        {
            "transferData": "Once",
            "id": 1
        },
        {
            "transferData": "Weekly",
            "id": 2
        },
        {
            "transferData": "Monthly",
            "id": 3
        }
    ]

    const endOnData = [
        {
            "endOnOption": "None",
            "id": 1
        },
        {
            "endOnOption": "Date",
            "id": 2
        },
        {
            "endOnOption": "NumTransfer",
            "id": 3
        }
    ]

    const numberOfTransfers = [
        {
            "numberOfTrans": "1",
            "id": 1
        },
        {
            "numberOfTrans": "2",
            "id": 2
        },
        {
            "numberOfTrans": "4",
            "id": 3
        }
    ]

    const onSubmit = (formData) => {
        const data = {
            fromAccount: formData.from_account
                ? accounts.find((row) => row.id === formData.from_account)
                : '',
            toAccount: formData.to_account
                ? accounts.find((row) => row.id === formData.to_account)
                : '',
            amount: formData.amount,
            when: startDate,
            howOften: formData.howOften,
            endOn: formData.endOn,
            endOn_NumTransfer: (formData.endOn == "NumTransfer") ? formData.endOn_NumTransfer : (formData.endOn == "Date") ? endDate : 'None'
        };
        navigation.push('MoveMoney.ReadyTransfer', { data });
    };

    return (
        <View style={{ flex: 1 }}>
            <Controller
                control={control}
                name="from_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts?.map((account) => ({
                            id: account.id,
                            name:
                                account.account_type +
                                ' ' +
                                account.account_number + '\n' + '$' +
                                account.balances.find(
                                    (b) => b.type === 'ACCOUNT_BALANCE'
                                ).balance / 100
                        }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.from')}
                        errorMessage={
                            errors.from_account && errors.from_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="to_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts?.map((account) => ({
                            id: account.id,
                            name:
                                account.account_type +
                                ' ' +
                                account.account_number + '\n' + '$' +
                                account.balances.find(
                                    (b) => b.type === 'ACCOUNT_BALANCE'
                                ).balance / 100
                        }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.to')}
                        errorMessage={
                            errors.to_account && errors.to_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="amount"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(\d+)(\.?)(\d){0,2}$/,
                        message: i18n.t(
                            'pages.moveMoney.transferAccounts.accountTransfer.form.invalidAmount'
                        ),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <Input
                        errorMessage={errors.amount && errors.amount.message}
                        errorStyle={
                            theme.ReactNativeElementsTheme.Input.errorStyle
                        }
                        label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.amount')}
                        textContentType="telephoneNumber"
                        dataDetectorTypes="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder={'$ 0.00'}
                        onChangeText={onChange}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            {isWeb() ?
                <WebDatePicker
                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                    date={startDate}
                    onChange={(selectedDate) => setStartDate(selectedDate)}
                /> :
                <DatePickerNative
                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                    minimumDate={addDays(new Date(),1)}
                />
            }
            <CheckBox
                checkedIcon={
                    <Icon
                        color={theme.colors.phthaloBlue}
                        type="ionicon"
                        name="square-outline"
                    />
                }
                uncheckedIcon={
                    <Icon
                        color={theme.colors.phthaloBlue}
                        type="ionicon"
                        name="checkbox"
                    />
                }
                containerStyle={webViewDesignSupport}
                title={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.repeatTranscation')}
                checked={isRepeatTranscation}
                onPress={() => setRepeatTranscation(!isRepeatTranscation)}
            />

            {!isRepeatTranscation ? (
                <View style={{ marginTop: 30 }}>
                    <Controller
                        control={control}
                        name="howOften"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                        }}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                InputComponent={Dropdown}
                                data={recurringData?.map((row) => row)}
                                maxHeight={200}
                                labelField={'transferData'}
                                valueField="transferData"
                                label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.howOften')}
                                errorMessage={
                                    errors.howOften && errors.howOften.message
                                }
                                placeholder={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.howOftenPlaceHolder')}
                                placeholderStyle={{ opacity: 0.5 }}
                                onChange={({ transferData }) => {
                                    onChange(transferData);
                                    setRepeatValue(transferData)
                                }}
                                value={value}
                                style={webViewDesignSupport}
                            />
                        )}
                    />

                    {repeatValue == "Weekly" || repeatValue == "Monthly" ?
                        <Controller
                            control={control}
                            name="endOn"
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    InputComponent={Dropdown}
                                    data={endOnData?.map((row) => row)}
                                    maxHeight={200}
                                    labelField={'endOnOption'}
                                    valueField="endOnOption"
                                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.endOn')}
                                    placeholder={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.endOnPlaceHolder')}
                                    placeholderStyle={{ opacity: 0.5 }}
                                    onChange={({ endOnOption }) => {
                                        onChange(endOnOption);
                                        setEndOnTransferValue(endOnOption)
                                    }}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        : null
                    }

                    {endOnTransferValue == "NumTransfer" ?
                        <Controller
                            control={control}
                            name="endOn_NumTransfer"
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    InputComponent={Dropdown}
                                    data={numberOfTransfers?.map((row) => row)}
                                    maxHeight={200}
                                    labelField={'numberOfTrans'}
                                    valueField="numberOfTrans"
                                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.numberOfTransfers')}
                                    placeholder={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.numberOfTransfersOnPlaceHolder')}
                                    placeholderStyle={{ opacity: 0.5 }}
                                    onChange={({ numberOfTrans }) => onChange(numberOfTrans)}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        : endOnTransferValue == "Date" ?
                            (isWeb() ?
                                <WebDatePicker
                                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                                    date={endDate}
                                    onChange={(selectedDate) => setEndDate(selectedDate)}
                                /> :
                                <DatePickerNative
                                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                                    date={new Date(endDate)}
                                    onChange={setEndDate}
                                />)
                            :
                            null
                    }

                </View>
            ) : null}
            <View
                style={{
                    marginTop: 15,
                    marginBottom: 15,
                }}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </View >
    );
};

MakeDepositForm.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    account: PropTypes.shape({
        id: PropTypes.string,
        account_type: PropTypes.string,
        account_number: PropTypes.string,
    }),
    accounts: PropTypes.array,
};
