import { Button, Text } from 'react-native-elements';
import { useWindowDimensions, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import { LinearGradient } from 'expo-linear-gradient';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { isWeb } from '../../../common/utils/platform.utils';
import { MaskedText } from 'react-native-mask-text';

export const ExternalAccountListScreen = () => {
    const {
        params: { externalAccounts, accounts, selectedExternalAccounts, screenName },
    } = useRoute();
    const navigation = useNavigation();
    const { width } = useWindowDimensions();

    return (
        <ModalLayout>
            <BankAccountTile externalAccounts={externalAccounts} accounts={accounts} selectedExternalAccounts={selectedExternalAccounts} screenName={screenName} />
            <View
                style={externalAccounts.length <= 2 ? {
                    marginTop: 15,
                    marginBottom: 15,
                    bottom: 20,
                    left: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                } : null}
            >
                <TouchableOpacity style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.colors.shadWhite,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: theme.colors.phthaloBlue,
                }} onPress={() => navigation.push('MoveMoney.linkExternalAccount', { externalAccounts })}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon
                            style={{ display: 'flex', justifyContent: 'center' }}
                            name="add-outline"
                            type="ionicon"
                            color={theme.colors.phthaloBlue}
                        />
                        <Button
                            buttonStyle={{
                                backgroundColor: theme.colors.shadWhite,
                                padding: 13,
                            }}
                            titleStyle={{
                                color: theme.colors.phthaloBlue,
                                fontSize: 15,
                                lineHeight: 26,
                                letterSpacing: 0.46,
                                fontFamily: 'DMSans_500Medium',
                            }}
                            title={i18n.t('pages.moveMoney.withdrawFund.externalAccountList.linkExternalAccount')}
                        >
                        </Button>
                    </View>
                </TouchableOpacity>
            </View>
        </ModalLayout>
    );
};

const BankAccountTile = ({ externalAccounts, accounts, selectedExternalAccounts, screenName }) => {
    const navigation = useNavigation();

    const accountBalance = { balance: "10000" }

    return (
        externalAccounts?.map((accountItem) => (
            <TouchableOpacity
                key={accountItem.id}
                style={{
                    height: 175,
                    marginBottom: 12,
                    borderRadius: 10,
                }}
                onPress={() => navigation.navigate(screenName, { accounts, selectedExternalAccounts: [accountItem] })}
            >
                <LinearGradient
                    {...theme.gradients.lightBlue}
                    style={{
                        paddingHorizontal: 21,
                        paddingVertical: 16,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 10,
                        background: '#FFFFFF',
                    }}
                >
                    <View key={accountItem.id} style={{ flex: 1, flexDirection: 'column' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    fontFamily: 'DMSans_700Bold',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                    marginRight: 10
                                }}
                            >
                                {i18n.t(`pages.moveMoney.withdrawFund.externalAccountList.accountType.${accountItem.type}`)}
                            </Text>
                            <MaskedText mask="**** **** **** 9999">
                                {accountItem.account_identifiers.number}
                            </MaskedText>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontFamily: 'DMSans_400Regular',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                {accountItem.account_owner_names.length > 0 ? accountItem.account_owner_names : i18n.t('common.accountHolderName')}
                            </Text>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontFamily: 'DMSans_400Regular',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                    marginRight: 5
                                }}
                            >
                                {i18n.t('pages.moveMoney.withdrawFund.externalAccountList.routingNumber')}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontFamily: 'DMSans_400Regular',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                {accountItem.routing_identifiers.ach_routing_number}
                            </Text>
                        </View>

                        <TextGradient
                            style={{
                                fontSize: 20,
                                marginTop: 'auto',
                                alignSelf: 'flex-start',
                                fontFamily: 'DMSans_700Bold',
                                marginBottom: 10
                            }}
                        >
                            <TextCurrency
                                value={
                                    accountBalance &&
                                    accountBalance.balance / 100
                                }
                            />
                            <View>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        marginLeft: 10,
                                    }}
                                >
                                    {externalAccounts.currency ? externalAccounts.currency : i18n.t('common.usDollar')}
                                </Text>
                            </View>
                        </TextGradient>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {/* Bank Logo */}
                            <Text
                                style={{
                                    fontSize: 17,
                                    fontFamily: 'DMSans_500Medium',
                                    textTransform: 'capitalize',
                                    alignItems: 'center',
                                }}
                            >
                                {accountItem.routing_identifiers.bank_name}
                            </Text>
                        </View>
                    </View>
                </LinearGradient>
            </TouchableOpacity>
        ))
    );
};

BankAccountTile.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    accounts: PropTypes.array,
    selectedExternalAccounts: PropTypes.array
};
