import { configureStore } from "@reduxjs/toolkit";
import auth0Reducer from "./auth0/slice";

export const store = configureStore({
	reducer: {
		users: auth0Reducer,
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
