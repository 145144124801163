import { Button, Text } from 'react-native-elements';
import { Image, ImageBackground, View } from 'react-native';
import { AlreadyMemberLink } from './components/already-member.component';
import PropTypes from 'prop-types';
import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ScanConnectProfile } from './components/scan-connect.component';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';

export const LandingScreen = ({ navigation }) => {
    const buttonContainer = { width: '100%', marginHorizontal: 0 };

    return (
        <View
            style={{
                flex: 1,
            }}
        >
            <ImageBackground
                source={require('../../../assets/images/background.png')}
                resizeMode="cover"
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <SafeAreaView
                    style={{
                        flex: 1,
                        marginTop: '50%',
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingHorizontal: 25,
                            paddingVertical: 0,
                        }}
                    >
                        <View
                            style={{
                                justifyContent: 'center',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                source={require('../../../assets/images/landingpage_logo.png')}
                                style={{
                                    width: 224,
                                    height: 56,
                                }}
                            />
                        </View>
                        <Text
                            style={{
                                fontFamily: 'DMSans_700Bold',
                                fontSize: 18,
                                textAlign: 'center',
                                color: '#FFFFFF',
                                marginTop: 22,
                            }}
                        >
                            {i18n.t('common.externalBankDetails')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'DMSans_400Regular',
                                fontSize: 16,
                                textAlign: 'center',
                                color: '#FAFAFA',
                                lineHeight: 24,
                                letterSpacing: 0.05,
                                marginVertical: 30,
                            }}
                        >
                            {i18n.t('common.productDescription')}
                        </Text>
                        <Button
                            buttonStyle={{
                                backgroundColor: '#FFD200',
                                padding: 13,
                                borderRadius: 4,
                                shadowColor: '#FFD200',
                                borderWidth: 2,
                                borderColor: '#FFD200',
                                shadowRadius: 5,
                                shadowOpacity: 0.7,
                                shadowOffset: { height: 5 }
                            }}
                            title={i18n.t('common.getStarted')}
                            titleStyle={theme.styles.titleStyleSecondary}
                            onPress={() => navigation.navigate('Auth.SignUp')}
                            containerStyle={{
                                ...buttonContainer,
                                marginTop: 0,
                                padding: 0,
                            }}
                        ></Button>
                        <AlreadyMemberLink
                            navigation={navigation}
                            textNote="#FAFAFA"
                            textLink="#FFFFFF"
                        />
                        {/* <View style={{ position: 'absolute', bottom: 8 }}>
                            <ScanConnectProfile navigation={navigation} />
                        </View> */}
                    </View>
                </SafeAreaView>
            </ImageBackground>
        </View>
    );
};

LandingScreen.propTypes = {
    navigation: PropTypes.object.isRequired,
};
