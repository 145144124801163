import React from 'react';
import { StyleSheet, View } from 'react-native';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import PropTypes from 'prop-types';
import { Text } from 'react-native-elements';
import { colors } from '../theme/colors';

const MinimalGooglePlacesInput = ({
    label = 'Search Address',
    placeholder = 'Search your current address',
    containerStyle,
    onAddressSelected,
}) => {
    const resultIntoString = (details) => {
        const addressInfo = {
            number: '',
            street: '',
            city: '',
            province: '',
            postalCode: '',
        };

        details.address_components.map((component) => {
            console.log(component);
            switch (component.types[0]) {
                case 'street_number': {
                    addressInfo.number = component.long_name;
                    break;
                }
                case 'route': {
                    addressInfo.street = component.long_name;
                    break;
                }
                case 'postal_code': {
                    addressInfo.postalCode = `${component.long_name}${addressInfo.postalCode}`;
                    break;
                }
                case 'postal_code_suffix': {
                    addressInfo.postalCode = `${addressInfo.postalCode}-${component.long_name}`;
                    break;
                }
                case 'locality': {
                    addressInfo.city = component.long_name;
                    break;
                }
                case 'administrative_area_level_1': {
                    addressInfo.province = component.short_name;
                    break;
                }
            }
        });

        // const finalString = `${addressInfo.number} ${addressInfo.street}, ${
        //     addressInfo.city
        // }, ${addressInfo.province}, ${addressInfo.postalCode.toUpperCase()}`;

        return {
            ...addressInfo,
            streetAddress: `${addressInfo.number} ${addressInfo.street}`,
        };
    };

    return (
        <View style={containerStyle}>
            <View>
                <Text style={styles.label}>{label}</Text>
            </View>

            <GooglePlacesAutocomplete
                textInputProps={{
                    placeholderTextColor: colors.tabBarInactiveTint,
                }}
                styles={{
                    textInput: styles.input,
                    powered: styles.searchPoweredImage,
                    row: {
                        backgroundColor: 'white',
                    },
                    poweredContainer: {
                        backgroundColor: 'white',
                    },
                }}
                placeholder={placeholder}
                // currentLocation
                fetchDetails
                keepResultsAfterBlur
                onPress={(data, details) => {
                    onAddressSelected(resultIntoString(details));
                }}
                query={{
                    key: 'AIzaSyCuEVz20ktmg8fAh2ugkEPM9DioVzI9uXs', // Config.GOOGLE_PLACES_KEY Here
                    language: 'en',
                }}
                renderRow={(data) => {
                    return (
                        <RowItem
                            title={data.structured_formatting.main_text}
                            subtitle={data.structured_formatting.secondary_text}
                        />
                    );
                }}
            />
        </View>
    );
};

export default MinimalGooglePlacesInput;

MinimalGooglePlacesInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onAddressSelected: PropTypes.func.isRequired,
    containerStyle: PropTypes.object,
};

const RowItem = ({ title, subtitle = '', hasPadding = false }) => {
    const wrapper = {
        padding: 12,
        borderColor: 'gray',
        borderBottomWidth: 0.5,
    };
    return (
        <View style={[styles.rowContainer, hasPadding && wrapper]}>
            <View style={styles.textContainer}>
                <Text>{title}</Text>
                {Boolean(subtitle.length) && (
                    <Text style={styles.rowText}>{subtitle}</Text>
                )}
            </View>
        </View>
    );
};

RowItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    textColor: PropTypes.string,
    hasPadding: PropTypes.bool,
};

const styles = StyleSheet.create({
    label: {
        color: colors.disabledText,
        fontSize: 16,
        marginBottom: 4,
    },
    input: {
        height: 50,
        backgroundColor: 'transparent',
        fontSize: 18,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: colors.textHeading,
    },
    searchPoweredImage: {
        opacity: 0.3,
        backgroundColor: 'white',
    },
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    textContainer: {
        flex: 1,
    },
    rowText: {
        paddingTop: 8,
    },
});
