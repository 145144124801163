import { Alert, View } from 'react-native';
import {
    PlaidLink as PlaidLinkSDK,
    openLink,
} from 'react-native-plaid-link-sdk';
import { PlaidLink as PlaidLinkWeb } from 'react-plaid-link';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import { log } from '../../../../App.context';
import { useToast } from 'react-native-toast-notifications';

export const PlaidLinkNative = ({
    onReady,
    onLinking,
    onSuccess,
    children,
    fetchAccessToken,
    addExternalAccounts,
    linkToken,
}) => {
    /**
     * Function called once Plaid UI closes successfully.
     */
    const saveExternalAccount = async ({ linkSuccess }) => {
        onLinking && onLinking();

        const institutionId =
            linkSuccess.metadata.institution &&
            linkSuccess.metadata.institution.id;
        const accountIds = linkSuccess.metadata.accounts.map((a) => a.id);
        const publicToken = linkSuccess.publicToken;
        const toast = useToast();

        try {
            // Fetch Access Token
            const accessToken = await fetchAccessToken({
                publicToken,
                institutionId,
            });
            log.debug('Plaid - Access Token', accessToken);

            // Add External Account
            const externalAccounts = await addExternalAccounts({
                accessToken: accessToken.vendor_access_token,
                accountIds,
            });
            log.debug('Plaid - External account linked', externalAccounts);

            onSuccess &&
                onSuccess(externalAccounts && externalAccounts.added_accounts);
        } catch (e) {
            log.error('Plaid - Error adding external account');
            {
                isWeb()
                    ? toast.show(e.message, {
                          type: 'dangerWithTitle',
                          data: {
                              title: i18n.t('common.errorTitle'),
                          },
                      })
                    : Alert.alert(i18n.t('pages.accounts.plaid.linkingError'));
            }
        }
    };

    const openPlaidLink = (onSuccess) => {
        openLink({
            tokenConfig: {
                token: linkToken,
                noLoadingState: false,
            },
            onSuccess: (linkSuccess) => {
                log.debug('linkSuccess', linkSuccess);
                onSuccess(linkSuccess.publicToken, {
                    metadata: linkSuccess.metadata,
                });
            },
        });
    };
    if (!onReady && !linkToken) {
        return <View />;
    }

    return isWeb() ? (
        <PlaidLinkWeb
            tokenConfig={{
                token: linkToken,
            }}
            onSuccess={async (linkSuccess) =>
                await saveExternalAccount({ linkSuccess })
            }
        >
            {children}
        </PlaidLinkWeb>
    ) : (
        <>
            {typeof children === 'function'
                ? children({ openPlaidLink })
                : children}
        </>
    );
};

PlaidLinkNative.propTypes = {
    accountId: PropTypes.string,
    onReady: PropTypes.func,
    onLinking: PropTypes.func,
    onSuccess: PropTypes.func,
    children: PropTypes.any,
    fetchAccessToken: PropTypes.func,
    addExternalAccounts: PropTypes.func,
    linkToken: PropTypes.string,
};
