import { AuthStateEnum } from '../../App.context';
import { LandingScreen } from '../screens/get-started/landing.screen';
import React from 'react';
import { SignIn } from '../screens/get-started/signin.screen';
import { SignUp } from '../screens/get-started/signup.screen';
import { useAppSelector } from '../hooks/store'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ChangePassword } from '../screens/get-started/change-password.screen';
const Stack = createNativeStackNavigator();

export const AuthenticationNavigator = () => {
    
    const {authState} = useAppSelector((state) => state.users)
    const shouldRender = authState === AuthStateEnum.Guest;

    return (
        shouldRender && (
            <Stack.Navigator
                initialRouteName="Auth.Landing"
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen
                    name="Auth.Landing"
                    options={{ headerShown: false }}
                    component={LandingScreen}
                />
                <Stack.Screen name="Auth.ChangePassword" component={ChangePassword} />
                <Stack.Screen name="Auth.SignIn" component={SignIn} />
                <Stack.Screen name="Auth.SignUp" component={SignUp} />
            </Stack.Navigator>
        )
    );
};
