import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'react-native-element-dropdown';
import { View } from 'react-native';
import React, { useState } from 'react';
import { ScrollableScreenLayout } from '../../../common/ui/components/screen-layout.component';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';
import states from 'states-us';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { WebDatePicker } from '../../home/components/web-date-picker.component';
import { DatePickerNative } from '../../home/components/date-picker.component';
import MaskInput from 'react-native-mask-input';
var addDays = require('date-fns/addDays')
export const LinkExternalCard = () => {
    const navigation = useNavigation();
    const [startDate, setStartDate] = useState(addDays(new Date(),1));
    const { params: {screenName}} = useRoute();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {}
    });

    const focusInput = true;
    
    const cardTypes = [
        {
            "name": "Credit Card",
            "id": 1
        },
        {
            "name": "Debit Card",
            "id": 2
        }
    ]

    const onSubmit = (formData) => {
        console.log('formData', screenName);
        const body = {
            beneficiaryName: formData.beneficiaryName,
            accountNumber: formData.accountNumber,
            bank_name: formData.bank_name,
        };
        navigation.navigate('MoveMoney.ExternalCardList', { externalAccounts : [formData], screenName:screenName });

        /* New API will be integrated */
        /* return api
            .patch('/customer', { body })
            .then((response) => {
                setBeneficiaryProfile(response);
                toast.show(i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.toast.success'), {
                    type: 'successWithTitle',
                });
                navigation.navigate('MoveMoney.beneficiaryInformationDetail');
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            }); */
    };

    const cardInputMask = [
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        '-',
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        '-',
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        '-',
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
    ];
    return (
        <ScrollableScreenLayout>
            <View style={{ flex: 1 }}>
                <Controller
                    control={control}
                    name="cardHolderName"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                focusInput &&
                                errors.cardHolderName &&
                                errors.cardHolderName.message
                            }
                            label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardHolderName')}
                            placeholder={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardHolderName')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="address"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.address &&
                                errors.address.message
                            }
                            label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.address.label')}
                            placeholder={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.address.placeholder')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="city"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.city &&
                                errors.city.message
                            }
                            label={i18n.t('forms.city.label')}
                            placeholder={i18n.t('forms.city.placeholder')}
                            autoCapitalize="none"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="postal_code"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.postal_code &&
                                errors.postal_code.message
                            }
                            label={i18n.t('forms.postalCode.label')}
                            placeholder={i18n.t('forms.postalCode.label')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="state"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={(states.sort())}
                            labelField="name"
                            valueField="abbreviation"
                            errorMessage={
                                errors.state &&
                                errors.state.message
                            }
                            label={i18n.t('forms.state.label')}
                            placeholder={i18n.t('forms.state.placeholder')}
                            placeholderStyle={{ opacity: 0.5 }}
                            onChange={({ abbreviation }) => onChange(abbreviation)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="cardType"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={cardTypes?.map((row) => row)}
                            labelField="name"
                            valueField="name"
                            errorMessage={
                                errors.cardType &&
                                errors.cardType.message
                            }
                            label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardHolderType')}
                            placeholder={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardHolderType')}
                            placeholderStyle={{ opacity: 0.5 }}
                            onChange={({ name }) => onChange(name)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="cardNumber"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.cardNumber &&
                                errors.cardNumber.message
                            }
                            InputComponent={MaskInput}
                            mask={cardInputMask}
                            keyboardType="phone-pad"
                            label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardNumber')}
                            placeholder={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardNumber')}
                            onChangeText={(masked) =>{onChange(masked)}}
                            maxLength={19}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            defaultValue={value}
                        />
                    )}
                />

                {isWeb() ?
                    <WebDatePicker
                        label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardExpirationDate')}
                        date={new Date(startDate)}
                        onChange={setStartDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    /> :
                    <DatePickerNative
                        label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardExpirationDate')}
                        date={new Date(startDate)}
                        onChange={setStartDate}
                        dateFormat="MM/YYYY"
                        minimumDate={addDays(new Date(),1)}
                    />
                }

                <Controller
                    control={control}
                    name="cvv"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                errors.cvv &&
                                errors.cvv.message
                            }
                            keyboardType="phone-pad"
                            label={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardCvv')}
                            placeholder={i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.cardCvv')}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            secureTextEntry={true}
                            maxLength={3}
                            style={webViewDesignSupport}
                            defaultValue={value}
                        />
                    )}
                />

                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </ScrollableScreenLayout>
    );
};
