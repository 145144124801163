import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { AuthStateEnum } from '../../../App.context';

const DEFAULT_STATE = {
  user: {
    sub: "",
    nickname:"",
    name:"",
    picture:"",
    updatedAt:"",
    email:"",
    emailVerified:false
  },
  authState: AuthStateEnum.Guest,
  profile: {
    first_name: '',
    middle_name: '',
    last_name: '',
    dob: '',
    email: '',
    ssn: '',
    kyc_status: '',
    phone_number: '',
    legal_address: {
        street_address_1: '',
        street_address_2: '',
        city: '',
        country_code: 'US',
        postal_code: '',
        state: '',
    },
    shipping_address: {
        street_address_1: '',
        street_address_2: '',
        city: '',
        country_code: '',
        postal_code: '',
        state: '',
    },
  }
}

export interface User {
	user: {
    sub: string
    nickname:string
    name:string
    picture:string
    updatedAt:string
    email:string
    emailVerified:boolean
  },
  authState: string,
  profile:{
    first_name: string,
    middle_name: string,
    last_name: string,
    dob: string,
    email: string,
    ssn: string,
    kyc_status: string,
    phone_number: string,
    legal_address: {
        street_address_1: string,
        street_address_2: string,
        city: string,
        country_code: string,
        postal_code: string,
        state: string,
    },
    shipping_address: {
        street_address_1: string,
        street_address_2: string,
        city: string,
        country_code: string,
        postal_code: string,
        state: string,
    },
  }
}

const initialState: User = (() => {
	return  DEFAULT_STATE;
})();

export const auth0Slice = createSlice({
	name: "auth0",
	initialState,
	reducers: {
		userState: (state, action) => {
      state.user = { ...action.payload }
		},
		authState: (state, action) => {
			 state.authState = action.payload
		},
    profileState: (state, action) => {
      state.profile = { ...action.payload }
    }
	},
});

export default auth0Slice.reducer;

export const { 
  userState,
   authState,
   profileState 
  } = auth0Slice.actions;