import { consoleTransport, logger } from 'react-native-logs';
import Constants from 'expo-constants';
import Frisbee from 'frisbee';
import React from 'react';

export const log = logger.createLogger({
    severity: 'debug',
    transport: consoleTransport,
    transportOptions: {
        colors: {
            debug: 'magentaBright',
            info: 'blueBright',
            warn: 'yellowBright',
            error: 'redBright',
        },
    },
});

export const api = new Frisbee({
    baseURI: Constants.manifest.extra.apiBaseURI,
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptor.register({
    request: function (path, options) {
        log.info('API - calling', path);
        return [path, options];
    },
    response: function (response) {
        if (response.ok) {
            log.debug('API - response', response.body);
            return response.body;
        } else {
            log.warn('API - error', response.err);
            throw response.err;
        }
    },
});

export const AuthStateEnum = {
    Guest: 'Guest',
    Customer_Pending_Onboarding: 'Customer_Pending_Onboarding',
    Customer_Active: 'Customer_Active',
};

export const DefaultProfile = {
    first_name: '',
    middle_name: '',
    last_name: '',
    dob: '',
    email: '',
    ssn: '',
    kyc_status: '',
    phone_number: '',
    legal_address: {
        street_address_1: '',
        street_address_2: '',
        city: '',
        country_code: 'US',
        postal_code: '',
        state: '',
    },
    shipping_address: {
        street_address_1: '',
        street_address_2: '',
        city: '',
        country_code: '',
        postal_code: '',
        state: '',
    },
};

export const DefaultBeneficiaryProfile = {
    beneficiaryName: 'ABC Company',
    accountNumber: '999123456',
    bank_name:'',
    bank_address: {
        street_address_1: '123 street, suite 400',
        street_address_2: '',
        city: 'San francisco,',
        country_code: 'US',
        postal_code: '94015',
        state: 'CA',
    }
}

export const MainContext = React.createContext({
    onError: () => {},
    beneficiaryProfile : DefaultBeneficiaryProfile,
    setBeneficiaryProfile: () => {},
});
