import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import WebView from 'react-native-webview';
import { api } from '../../../../../App.context';
import i18n from 'i18n-js';
import { useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';

export const ActivateCard = () => {
    const [widgetPath, setWidgetPath] = useState('url');
    const toast = useToast();

    const {
        params: { selectedCard, account },
    } = useRoute();

    const fetchActivateCardWidget = async () => {
        toast.hideAll();
        api.get(
            `/card/${selectedCard.id}/card_widget_url?widgetType=activate_card&accountId=${account.id}`
        )
            .then((response) => {
                const result = JSON.parse(response);
                setWidgetPath(result.url);
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useEffect(() => fetchActivateCardWidget(), []);

    return (
        <ModalLayout
            title={i18n.t('pages.home.manageCard.activateCard.title')}
            subtitle={i18n.t('pages.home.manageCard.activateCard.subtitle')}
        >
            {!widgetPath && <ActivityIndicator />}

            {widgetPath && (
                <WebView
                    originWhitelist={['*']}
                    source={{ uri: widgetPath }}
                    containerStyle={{
                        position: 'absolute',
                        left: 7.5,
                        top: 180,
                        margin: 0,
                        width: 900,
                        flex: 1,
                        height: 400,
                    }}
                    renderError={() => {
                        //This empty function prevents rendering the error into theWebView
                    }}
                />
            )}
        </ModalLayout>
    );
};
