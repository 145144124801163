import { View, Pressable } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import React, { useState } from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import theme from '../../../common/ui/theme';
import { CustomAlert } from '../../../common/ui/components/custom-alert.component';
import { MaskedText } from 'react-native-mask-text';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { useWindowDimensions } from 'react-native';

export const ManageTransferDetail = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const [modalVisible, setModalVisible] = useState(false);

    const {
        params: { data },
    } = useRoute();
    const accountFromBalance = data?.[0]?.senderAccount?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );
    const accountToBalance = data?.[0]?.receiverAccount?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );

    const moneyTransfer = (formData) => {
        const amountInCents = formData.amount * 100;
    };

    return (
        <ModalLayout>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.from')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.[0]?.account_type} {' '}
                        <MaskedText mask="**** **** **** 9999">
                            {data?.[0]?.sender_account_number}
                        </MaskedText>
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountFromBalance &&
                                accountFromBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.to')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.[0]?.account_type} {' '}
                        <MaskedText mask="**** **** **** 9999">
                            {data?.[0]?.receiver_account_number}
                        </MaskedText>
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountToBalance &&
                                accountToBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.amount')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {'$' + data?.[0]?.accountTransferAmount.balance / 100}
                    </Text>
                </View>
                {data?.[0]?.when && (
                    <View
                        style={{
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <Text style={theme.styles.textBold}>
                            {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.when')}
                        </Text>
                        <Text style={theme.styles.textRegular}>
                        {(data?.[0]?.when)}
                        </Text>
                    </View>
                )}
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.repeat')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.[0]?.transfer_type}
                    </Text>
                </View>
                <View style={{ flexDirection: 'column' }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.form.endon')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                    {(data?.[0]?.endOn)}
                        {/* {data?.endOn == "NumTransfer" ? data?.endOn_NumTransfer : data?.endOn == "Date" ? moment(data?.endOn_NumTransfer).format('MMMM DD, YYYY') : 'None'} */}
                    </Text>
                </View>
                <View style={{ marginTop: 'auto' }}>
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        title={i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.transfer')}
                        onPress={() => navigation.navigate("Home")}
                        // onPress={() => moneyTransfer(data)}
                    />
                    <CustomAlert
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        title={i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.cancelAllTransfer')}
                        message={i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.confirmationMessage') + ' ' + `${data?.[0]?.account_type}` + ' ' + `${data?.[0]?.receiver_account_number}`}
                    />
                    <Pressable
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onPress={() => setModalVisible(true)}
                    >
                        <Text style={theme.styles.navigationButton}>
                            {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.canceltransfer')}
                        </Text>
                    </Pressable>
                </View>
            </View>
        </ModalLayout>
    );
};
