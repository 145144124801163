import { Button, Text } from 'react-native-elements';
import { Image, SafeAreaView, View, useWindowDimensions } from 'react-native';
import { isAndroid, isWeb } from '../../common/utils/platform.utils';
import React from 'react';
import i18n from 'i18n-js';
import { openURL } from 'expo-linking';
import theme from '../../common/ui/theme';
import { useAuth0Actions } from '../../hooks/useAuth0Actions';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export const KYCFailureScreen = () => {
    const { top } = useSafeAreaInsets();
    const { width } = useWindowDimensions();
    const { signOut } = useAuth0Actions()

    const callContactNumber = () => {
        openURL(i18n.t('pages.kycRedPath.contactNumberHref'))
            .then((result) => {
                console.log('callContactNumber: ', result);
            })
            .catch((err) => {
                console.log('callContactNumber: ', err);
            });
    };

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={{ flex: 1, height: top + 117, marginTop: isAndroid() ? 20 : 0 }}>
                <View
                    style={{
                        flexDirection: 'row',
                        marginTop: 20,
                        justifyContent: 'center',
                        backgroundColor: '#FFFFFF',
                        width: '100%'
                    }}
                >
                    <Image
                        source={require('../../../assets/images/registrationpage_logo.png')}
                        style={{ width: 128, height: 34 }}
                    />
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontSize: 17,
                            color: theme.colors.phthaloBlue,
                            marginTop: 20,
                            fontFamily: 'DMSans_700Bold',
                            alignContent: 'center',
                            textAlign: 'center',
                            width: '76%',
                        }}
                    >
                        {i18n.t('pages.kycRedPath.errorMessage')}
                    </Text>
                    <Text
                        h5
                        style={{
                            marginTop: 20,
                            alignContent: 'center',
                            fontFamily: 'DMSans_700Bold',
                            textAlign: 'center',
                            width: '65%',
                        }}
                    >
                        {i18n.t('pages.kycRedPath.customerServiceMessage')}
                        <Text
                            onPress={() => callContactNumber()}
                            style={{ ...theme.typography.phoneLink }}
                        >
                            {i18n.t('pages.kycRedPath.contactNumber')}
                        </Text>
                    </Text>
                </View>
                <View
                    style={{
                        left: 30,
                        bottom: 20,
                        position: isWeb() ? 'fixed' : 'absolute',
                        width: width - 50,
                    }}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        onPress={() => signOut()}
                        title={i18n.t('common.exit')}
                    />
                </View>
            </View>
        </SafeAreaView>
    );
};
