import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import { Text } from 'react-native-elements';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from '../../hooks/store';

export const ProfileScreen = () => {
    const navigation = useNavigation();
    const { profile } = useAppSelector((state) => state.users);
    return (
        
       <ScrollableScreenLayout>
            <Container>
                <View>
                    <Text style={[styles.profileTitle, styles.fs17]}>
                        {i18n.t('pages.profile.name')}
                    </Text>
                    <Text style={styles.profileSubTitle}>
                        {profile.first_name} {profile.last_name}
                    </Text>
                </View>
            </Container>
            <Container>
                <View>
                    <Text style={[styles.profileTitle, styles.fs17]}>
                        {i18n.t('pages.profile.addresses')}
                    </Text>
                    <Text style={[styles.profileTitle, styles.fs16]}>
                        {i18n.t('pages.profile.homeAddress')}
                    </Text>
                    <Address address={profile.legal_address} />
                    <Text style={[styles.profileTitle, styles.fs16]}>
                        {i18n.t('pages.profile.mailingAddress')}
                    </Text>
                    <Address address={profile.shipping_address} />
                </View>
                <TouchableOpacity
                    onPress={() =>
                        navigation.push('Profile.EditAddresses', {
                            profile,
                        })
                    }
                >
                    <View
                        accessibility
                        accessibilityLabel={i18n.t(
                            'pages.profile.editAddress.accessibility.button'
                        )}
                    >
                        <Image
                            source={require('../../../assets/images/edit.png')}
                            style={{
                                width: 22,
                                height: 22,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </Container>
            <Container>
                <View>
                    <Text style={[styles.profileTitle, styles.fs17]}>
                        {i18n.t('pages.profile.phoneNumber')}
                    </Text>
                    <Text style={[styles.profileTitle, styles.fs16]}>
                        {i18n.t('pages.profile.primaryPhone')}
                    </Text>
                    <PhoneNumber phoneNumber={profile.phone_number} />
                    <Text style={[styles.profileTitle, styles.fs17]}>
                        {i18n.t('pages.profile.mobilePhone')}
                    </Text>
                    <PhoneNumber />
                </View>
                <TouchableOpacity
                    onPress={() =>
                        navigation.push('Profile.EditPhone', {
                            profile,
                        })
                    }
                >
                    <View
                        accessibility
                        accessibilityLabel={i18n.t(
                            'pages.profile.editPhoneNumber.accessibility.button'
                        )}
                    >
                        <Image
                            source={require('../../../assets/images/edit.png')}
                            style={{
                                width: 22,
                                height: 22,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </Container>
            <Container>
                <View>
                    <Text style={[styles.profileTitle, styles.fs17]}>
                        {i18n.t('pages.profile.email')}
                    </Text>
                    <Text style={styles.profileSubTitle}>
                        {i18n.t('pages.profile.primaryEmail')}
                    </Text>
                    <Text style={styles.profileSubTitle}>
                        {profile.email}
                    </Text>
                </View>
                <TouchableOpacity
                    onPress={() =>
                        navigation.push('Profile.EditEmail', {
                            profile,
                        })
                    }
                >
                    <View
                        accessibility
                        accessibilityLabel={i18n.t(
                            'pages.profile.editEmail.accessibility.button'
                        )}
                    >
                        <Image
                            source={require('../../../assets/images/edit.png')}
                            style={{
                                width: 22,
                                height: 22,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </Container>
        </ScrollableScreenLayout>
           
        
    );
};

/*
    A clickable, bordered box containing a section of the profile
*/
const Container = ({ children }) => (
    <View
        style={{
            ...theme.styles.lightBlueBorder,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        {children}
    </View>
);

Container.propTypes = {
    children: PropTypes.any,
};

const Address = ({ address }) => (
    <View style={{ marginBottom: 10 }}>
        <Text style={styles.profileSubTitle}>
            {address.address_line_1}
        </Text>
        {address.address_line_2 ? (
            <Text style={styles.profileSubTitle}>
                {address.address_line_2}
            </Text>
        ) : null}
        <Text style={styles.profileSubTitle}>
            <Text>{address.city} </Text>
            <Text>{address.state} </Text>
            <Text>{address.postal_code}</Text>
        </Text>
    </View>
);

Address.propTypes = {
    address: PropTypes.object,
};

const PhoneNumber = ({ phoneNumber }) => {
    // Phone number format: +1xxxYYYzzzz
    // This only works for North America area codes
    const areaCode = phoneNumber?.substring(2, 5);
    const prefix = phoneNumber?.substring(5, 8);
    const lineNumber = phoneNumber?.substring(8);
    return phoneNumber ? (
        <Text style={[styles.profileSubTitle, styles.mb10]}>
            ({areaCode}) {prefix}-{lineNumber}
        </Text>
    ) : (
        <Text style={[styles.profileSubTitle, styles.mb10]}>
            {i18n.t('pages.profile.noPhoneNumber')}
        </Text>
    );
};

const styles = StyleSheet.create({
    profileTitle: {
        fontFamily: 'DMSans_700Bold',
        lineHeight: 20,
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'capitalize',
        alignItems: 'center',
        marginBottom: 10,
    },
    fs17: {
        fontSize: 17,
    },
    fs16: {
        fontSize: 16,
    },
    profileSubTitle: {
        fontSize: 12,
        fontFamily: 'DMSans_400Regular',
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: 20,
        letterSpacing: 0.4,
    },
    mb10: {
        marginBottom: 10
    }
});

PhoneNumber.propTypes = {
    phoneNumber: PropTypes.string,
};

