import { ListItem, Text } from 'react-native-elements';
import React, { useEffect, useState } from 'react';
import { RefreshControl, SectionList, View } from 'react-native';
import theme, {
    ReactNativeElementsTheme,
    ReactNavigationTheme,
} from '../../../common/ui/theme';
import PropTypes from 'prop-types';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { api } from '../../../../App.context';
import i18n from 'i18n-js';
import moment from 'moment';
import { useToast } from 'react-native-toast-notifications';

export const TransactionsList = ({
    accountId,
    showPending,
    fromDate,
    toDate,
    children,
}) => {
    const toast = useToast();
    const [pendingTransactions, setPendingTransactions] = useState([]);
    const [pendingTransactionsToken, setPendingTransactionsToken] = useState();
    const [postedTransactions, setPostedTransactions] = useState([]);
    const [postedTransactionsToken, setPostedTransactionsToken] = useState();
    const [refreshing, setRefreshing] = useState(false);
    const [pending, setPending] = useState(showPending);

    const fetchTransactions = async (
        type,
        append,
        transactions,
        setTransactions,
        nextPageToken,
        setNextPageToken
    ) => {
        toast.hideAll();
        try {
            const nextPageQueryParam = nextPageToken
                ? `&page_token=${nextPageToken}`
                : '';
            const toDateParam = toDate ? `&to_date=${toDate}` : '';

            const { result, next_page_token } = await api.get(
                `/accounts/${accountId}/transfers?type=${type}&from_date=${fromDate}${toDateParam}${nextPageQueryParam}`
            );
            const incomingTransactions = result || [];
            const updatedTransactions = append
                ? [...transactions, ...incomingTransactions]
                : [...incomingTransactions];
            setTransactions(updatedTransactions);
            setNextPageToken(next_page_token);
        } catch (err) {
            console.error(`error retrieving ${type} transactions: `, err);
            toast.show(err.message, {
                type: 'dangerWithTitle',
                data: {
                    title: i18n.t('pages.home.transactions.errorMessage'),
                },
            });
        }
    };

    const fetchApiTransactions = async (
        append = false,
        transactionPending = pending
    ) => {
        if (showPending) {
            /* fetch pending transcations api */
            await fetchTransactions(
                'pending',
                append,
                pendingTransactions,
                setPendingTransactions,
                pendingTransactionsToken,
                setPendingTransactionsToken
            );
        }
        if (showPending == transactionPending) {
            /* fetch post transcations api */
            await fetchPostedTransactions();
        }
    };
    const fetchPostedTransactions = async (append = false) => {
        fetchTransactions(
            'posted',
            append,
            postedTransactions,
            setPostedTransactions,
            postedTransactionsToken,
            setPostedTransactionsToken
        );
    };

    useEffect(() => {
        let isCancelled = false;
        setPending(showPending);
        if (!isCancelled) {
            fetchApiTransactions(false, showPending);
        }
        return () => {
            isCancelled = true;
        };
    }, [fromDate, toDate, showPending]);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        fetchApiTransactions();
        setRefreshing(false);
    }, []);

    const sections = showPending
        ? [
              {
                  title: i18n.t('pages.home.transactions.authorizedTitle'),
                  data: pendingTransactions,
                  key: 'pending',
              },
              {
                  title: i18n.t('pages.home.transactions.postedTitle'),
                  data: postedTransactions,
                  key: 'posted',
              },
          ]
        : [
              {
                  data: postedTransactions,
                  key: 'posted',
              },
          ];
    return (
        <SectionList
            extraData={children}
            ListHeaderComponent={() => children}
            sections={sections}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
                <TransactionItem
                    date={item.date}
                    value={item.value}
                    label={item.label}
                />
            )}
            renderSectionHeader={({ section: { title } }) =>
                title ? (
                    <Text
                        style={{
                            ...ReactNativeElementsTheme.Text.h3Style,
                            marginTop: 0,
                            paddingTop: 22,
                            backgroundColor:
                                ReactNavigationTheme.colors.background,
                        }}
                    >
                        {title}
                    </Text>
                ) : null
            }
            renderSectionFooter={({ section: { data, key } }) => (
                <>
                    {data.length <= 0 ? (
                        <Text style={{ textAlign: 'center' }}>
                            {i18n.t('pages.home.transactions.noTransactions')}
                        </Text>
                    ) : null}
                    {key === 'pending' ? (
                        <View
                            style={{
                                backgroundColor: theme.colors.shadeBlue,
                                height: 2,
                                marginTop: 20,
                                marginBottom: 15,
                            }}
                        />
                    ) : null}
                </>
            )}
            onEndReached={() => {
                if (postedTransactionsToken) {
                    fetchPostedTransactions(true);
                }
            }}
            onEndReachedThreshold={0.2}
        />
    );
};

TransactionsList.propTypes = {
    accountId: PropTypes.string,
    showPending: PropTypes.bool,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    children: PropTypes.node,
};

const TransactionItem = ({ date, label, value }) => {
    const formattedDate = moment(date, moment.ISO_8601).format('MMM D, YYYY');
    const valueInDollars = value / 100;
    return (
        <ListItem
            containerStyle={{
                paddingHorizontal: 0,
                paddingVertical: 15,
            }}
        >
            <ListItem.Content>
                <ListItem.Subtitle>
                    <Text
                        style={{
                            fontSize: 12,
                            color: theme.colors.tabBarInactiveTint,
                        }}
                    >
                        {formattedDate}
                    </Text>
                </ListItem.Subtitle>
                <ListItem.Title style={{ marginTop: 5 }}>
                    <Text style={{ fontSize: 15 }}>{label}</Text>
                </ListItem.Title>
            </ListItem.Content>
            <TextCurrency
                value={valueInDollars}
                style={{
                    fontSize: 18,
                    color:
                        value > 0
                            ? theme.colors.phthaloBlue
                            : theme.colors.textBody,
                }}
            />
        </ListItem>
    );
};

TransactionItem.propTypes = {
    id: PropTypes.string,
    date: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
};
