import { ActivityIndicator, Image, TouchableOpacity, View } from 'react-native';
import { AuthStateEnum, MainContext, api } from '../../../App.context';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { Icon, Text } from 'react-native-elements';
import React, { useEffect, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { AccountTile } from '../accounts/components/account-tile.component';
import { CheckListItem } from '../../common/ui/elements/check-list-item.element';
import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import { TextGradient } from '../../common/ui/elements/text-gradient.element';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';
import { useAppSelector } from '../../hooks/store';

export const HomeScreen = () => {
    const [accounts, setAccounts] = useState([]);
    const [activeAccount, setActiveAccount] = useState();
    const toast = useToast();
    const navigation = useNavigation();
    const { profile } = useAppSelector((state) => state.users);
    const fetchAccounts = () => {
        setAccounts(null);
        api.get('/accounts')
            .then((response) => setAccounts(response.accounts))
            .catch((err) => {
                toast.hideAll();
                setAccounts([]);
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            fetchAccounts();
        }, [])
    );

    return (
           
        <ScrollableScreenLayout
            fetchAccounts={fetchAccounts}
            refresh={true}
        >
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    alignSelf: 'flex-start',
                    marginBottom: 30,
                }}
            >
                <TextGradient
                    style={{
                        fontSize: 32,
                        fontFamily: 'DMSans_400Regular',
                        fontWeight: '400',
                        lineHeight: 37,
                        color: theme.colors.phthaloBlue,
                    }}
                >
                    {i18n.t('pages.home.welcome') + ', '}
                    {profile && profile.first_name}
                </TextGradient>
            </View>
            <VerifyIdentity />
            <Text h4>{i18n.t('pages.home.accountsTitle')}</Text>
            <AccountsCarousel
                accounts={accounts}
                onChange={(account) => setActiveAccount(account)}
            />
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                <TouchableOpacity
                    style={{
                        marginTop: 15,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onPress={() => navigation.push('Home.OpenAccount')}
                >
                    <Icon
                        name="add"
                        type="ionicon"
                        color={theme.colors.phthaloBlue}
                    />
                    <Text
                        style={{
                            color: theme.colors.phthaloBlue,
                            fontFamily: 'DMSans_500Medium',
                            lineHeight: 26,
                            letterSpacing: 0.46,
                            marginLeft: 5,
                            fontSize: 15,
                            ...theme.typography.mediumFontWeight,
                        }}
                    >
                        {i18n.t('pages.home.openAccount')}
                    </Text>
                </TouchableOpacity>
            </View>
            <MoveMoney accounts={accounts} account={activeAccount} />
        </ScrollableScreenLayout>
           
       
    );
};

const AccountsCarousel = ({ accounts, onChange }) => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const [activeIndex, setActiveIndex] = useState(0);

    const selectAccount = (index) => {
        if (accounts !== null) {
            setActiveIndex(index);
            onChange(accounts[index]);
        }
    };

    const viewAccountSummary = (account) => {
        navigation.push('Home.AccountSummary', { accounts, account });
    };

    useEffect(() => selectAccount(0), [accounts]);

    if (accounts === null) {
        return (
            <View
                style={{
                    height: 180,
                    justifyContent: 'center',
                }}
            >
                <ActivityIndicator />
            </View>
        );
    }
    return (
        <View>
            <Carousel
                containerCustomStyle={{
                    marginLeft: -25,
                    marginTop: 10,
                    paddingBottom: 20,
                }}
                data={accounts}
                inactiveSlideScale={0.85}
                inactiveSlideOpacity={0.35}
                inactiveSlideShift={10}
                renderItem={(c) => (
                    <AccountTile
                        account={c.item}
                        onPress={() => viewAccountSummary(c.item)}
                    />
                )}
                sliderWidth={width}
                itemWidth={width}
                onSnapToItem={(index) => selectAccount(index)}
            />
            <Pagination
                dotColor={theme.colors.textBody}
                inactiveDotColor={theme.colors.tabBarInactiveTint}
                activeDotIndex={activeIndex}
                dotsLength={accounts.length}
            />
        </View>
    );
};

AccountsCarousel.propTypes = {
    accounts: PropTypes.array,
    onChange: PropTypes.func,
};

const MoveMoney = ({ accounts }) => {
    const navigation = useNavigation();
    const selectedExternalAccounts = [];
    return (
        <View style={{ flex: 1, marginTop: 35 }}>
            <Text h4>{i18n.t('pages.home.news.title')}</Text>
            <ActionItem
                title={i18n.t(
                    'pages.moveMoney.withdrawFund.title'
                )}
                description={i18n.t(
                    'pages.moveMoney.withdrawFund.description'
                )}
                iconName={require('../../../assets/images/outbound.png')}
                onClick={() =>
                    navigation.push('MoveMoney.WithDrawFundDetail', { accounts, selectedExternalAccounts })
                }
            >
            </ActionItem>
            <ActionItem
                title={i18n.t(
                    'pages.moveMoney.fundMyAccount.title'
                )}
                description={i18n.t(
                    'pages.moveMoney.fundMyAccount.description'
                )}
                iconName={require('../../../assets/images/input.png')}
                onClick={() =>
                    navigation.push('MoveMoney.FundMyAccountDetail', { accounts, selectedExternalAccounts })
                }
            />
            <ActionItem
                title={i18n.t(
                    'pages.moveMoney.transferAccounts.title'
                )}
                description={i18n.t(
                    'pages.moveMoney.transferAccounts.description'
                )}
                iconName={require('../../../assets/images/swapHorizontalCircle.png')}
                onClick={() =>
                    navigation.push('MoveMoney.AccountDetails', { accounts })
                }
            />
            <ActionItem
                title={i18n.t(
                    'pages.moveMoney.wireTransfer.title'
                )}
                description={i18n.t(
                    'pages.moveMoney.wireTransfer.description'
                )}
                iconName={require('../../../assets/images/language.png')}
                onClick={() =>
                    navigation.push('MoveMoney.WireTransferDetail', { accounts })
                }
            />
            <ActionItem
                title={i18n.t(
                    'pages.moveMoney.manageTransfer.title'
                )}
                description={i18n.t(
                    'pages.moveMoney.manageTransfer.description'
                )}
                iconName={require('../../../assets/images/calenderEvent.png')}
                onClick={() =>
                    navigation.push('MoveMoney.ManageTransferList')
                }
            />
        </View>
    );
};

MoveMoney.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    accounts: PropTypes.array,
};

const ActionItem = ({
    title,
    description,
    iconName,
    children,
    onClick,
}) => {
    return (
        <View style={theme.styles.mediumBlueBorder}>
            <TouchableOpacity
                onPress={() => onClick()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <Image
                                source={iconName}
                                style={{ width: 22, height: 22 }}
                            />
                            <Text
                                style={{
                                    fontSize: 17,
                                    marginLeft: 10,
                                    fontWeight: '700',
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    ...theme.typography.boldFontWeight,
                                }}
                            >
                                {title}
                            </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>{children}</View>
                    </View>
                    <Text
                        style={{
                            marginTop: 10,
                            fontSize: 12,
                            fontWeight: '400',
                            lineHeight: 20,
                            letterSpacing: 0.4,
                            color: 'rgba(0, 0, 0, 0.87)',
                            ...theme.typography.regularFontWeight,
                        }}
                    >
                        {description}
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

ActionItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.any,
    iconName: PropTypes.any,
};

const VerifyIdentity = () => {
    const navigation = useNavigation();
    const {authState, profile} = useAppSelector((state) => state.users)
    const shouldRender =
        authState === AuthStateEnum.Customer_Active &&
        profile.kyc_status === 'REVIEW';

    return (
        shouldRender && (
            <View style={{ marginBottom: 30 }}>
                <TouchableOpacity
                    onPress={() => navigation.push('Home.VerifyIdentity')}
                >
                    <View
                        style={{
                            backgroundColor: theme.colors.phthaloBlue,
                            borderRadius: 4,
                            padding: 16,
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Icon
                                    name="check-circle-outline"
                                    color={theme.colors.shadWhite}
                                    style={{ marginRight: 12 }}
                                />
                                <View style={{ flex: 1 }}>
                                    <Text
                                        style={{
                                            fontSize: 17,
                                            marginBottom: 4,
                                            lineHeight: 24,
                                            letterSpacing: 0.15,
                                            color: theme.colors.textPrimaryHeading,
                                            ...theme.fontFamilySans.mediumFontWeight
                                        }}
                                    >
                                        {i18n.t('pages.kycInReview.verifyIdentity')}
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            marginBottom: 8,
                                            lineHeight: 20,
                                            letterSpacing: 0.15,
                                            color: theme.colors.textPrimaryHeading,
                                            ...theme.fontFamilySans.regularFontWeight
                                        }}
                                    >
                                        {i18n.t('pages.kycInReview.subtitle')}
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            lineHeight: 20,
                                            letterSpacing: 0.15,
                                            textDecorationLine: 'underline',
                                            color: theme.colors.textPrimaryHeading,
                                            ...theme.fontFamilySans.regularFontWeight
                                        }}
                                    >
                                        {i18n.t('pages.kycInReview.verifyNow')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    );
};
