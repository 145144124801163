/* eslint-disable react/react-in-jsx-scope */
import "react-native-gesture-handler";
import App from "./App";
import {AppRegistry} from 'react-native';
import { Provider } from "react-redux";
import { store } from "./src/store";
import { registerRootComponent } from "expo";
import { LogBox } from "react-native";


LogBox.ignoreLogs(['VirtualizedLists should never be nested',]);
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

export default function EntryPoint() {
  return (
    <Provider store={store}>
    <App />
  </Provider>
  );
}

registerRootComponent(EntryPoint);
