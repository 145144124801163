import { Button, Input, Text } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { SafeAreaView, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { MainContext } from '../../../App.context';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/native';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { SignupMemberLink } from './components/signup-member.component';
import { isWeb } from '../../common/utils/platform.utils';
import { useAuth0Actions } from '../../hooks/useAuth0Actions';
import { useAppSelector } from '../../hooks/store';

export const SignIn = () => {
    return (    
        <MainContext.Consumer>
            {({ onError }) => (
                <SafeAreaView style={{ flex: 1 }}>
                    <OnboardingPageLayout title={i18n.t('pages.signin.title')}>
                        <View style={{ marginTop: 50 }} />
                        <SignInForm onError={onError} />
                        <SignupMemberLink/>
                    </OnboardingPageLayout>
                </SafeAreaView>
            )}
        </MainContext.Consumer>
    );
};

const SignInForm = ({ onError }) => {
    const { signIn } = useAuth0Actions()
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigation();
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: { email: '', password: '' },
    });
    const focusInput = true;
    const toast = useToast();

    return (
        <View style={{ flex: 1 }}>
            <Controller
                control={control}
                name="email"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(.+)@(.+)\.(.{2,6})$/,
                        message: i18n.t('forms.email.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            focusInput && errors.email && errors.email.message
                        }
                        label={i18n.t('forms.email.label')}
                        placeholder={i18n.t('forms.email.placeholder')}
                        autoCapitalize="none"
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        autoFocus={true}
                    />
                )}
            />

            <Controller
                control={control}
                name="password"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    minLength: {
                        value: 6,
                        message: i18n.t('forms.password.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        keyboardType="email-address"
                        secureTextEntry={!showPassword}
                        errorMessage={
                            errors.password && errors.password.message
                        }
                        label={i18n.t('forms.password.label')}
                        placeholder={i18n.t('forms.password.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        rightIcon={{
                            type: 'material-community',
                            name: showPassword
                                ? 'eye-off-outline'
                                : 'eye-outline',
                            onPress: () => setShowPassword(!showPassword),
                        }}
                        style={webViewDesignSupport}
                    />
                )}
            />
            <TouchableOpacity
                style={{
                    marginBottom: 15,
                    outlineStyle: isWeb() ? 'none' : null,
                }}
                onPress={() => navigation.navigate('Auth.ChangePassword')}
            >
                <Text
                    style={{
                        fontFamily: 'DMSans_500Medium',
                        color: theme.colors.phthaloBlue,
                        fontSize: 14,
                        lineHeight: 24,
                        textAlign: 'right',
                        textDecorationLine: 'underline',
                    }}
                >
                    {i18n.t('common.forgotPassword')}
                </Text>
            </TouchableOpacity>
            <Button
                buttonStyle={theme.styles.buttonStylePrimary}
                titleStyle={theme.styles.titleStylePrimary}
                loading={isLoading}
                disabled={!isDirty || !isValid}
                title={i18n.t('common.signin')}
                onPress={handleSubmit((formData) => {
                    toast.hideAll();
                    setLoading(true);
                    signIn(formData).catch((e) => {
                        toast.show(e.message, {
                            type: 'dangerWithTitle',
                            data: {
                                title: i18n.t('common.errorTitle'),
                            },
                        });
                        onError(e.message);
                        setLoading(false);
                    });
                })}
            />
        </View>
    );
};

SignInForm.propTypes = {
    onError: PropTypes.func,
};
