import { View, useWindowDimensions } from 'react-native';
import { Button, CheckBox, Icon, Input, Text } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState,useRef } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Dropdown } from 'react-native-element-dropdown';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { DatePickerNative } from '../../home/components/date-picker.component';
import { useToast } from 'react-native-toast-notifications';
import { api } from '../../../../App.context';
import { isWeb } from '../../../common/utils/platform.utils';
import { SendMoneyOutAccount } from '../../../common/ui/components/send-money-out.component';
import { WebDatePicker } from '../../home/components/web-date-picker.component';
import { CustomHeaderComponent } from '../../../navigation/screen-options.component';
import { InformationDetail } from '../../../common/ui/components/information-details.component';
var addDays = require('date-fns/addDays')
export const WithDrawFundDetail = () => {
    const [externalAccounts, setExternalAccounts] = useState(null);
    const toast = useToast();
    const {
        params: { accounts, selectedExternalAccounts },
    } = useRoute();

    const fetchExternalAccountsAccounts = () => {
        toast.hideAll();
        api.get('/external_accounts')
            .then((r) => setExternalAccounts(r.external_accounts))
            .catch((err) => {
                isWeb()
                    ? toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    })
                    : Alert.alert(i18n.t('common.errorTitle'), err.message);
            });
    };

    useEffect(() => fetchExternalAccountsAccounts(), []);

    return (
        <>
            <CustomHeaderComponent maintitle={i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.title')} subtitle={i18n.t('common.details')} />
            <ModalLayout>
                <SendMoneyForm accounts={accounts} externalAccounts={externalAccounts} selectedExternalAccounts={selectedExternalAccounts} />
            </ModalLayout>
        </>
    );
};

const SendMoneyForm = ({ accounts, externalAccounts, selectedExternalAccounts }) => {
    const navigation = useNavigation();
    const [startDate, setStartDate] = useState(addDays(new Date(),1));

    const accountInputRef = useRef(null)
    const cardInputRef = useRef(null)
    const { width, height } = useWindowDimensions();
    const toast = useToast();
    const products = [
        {
            "id": "1",
            "name": "External Account",
        },
        {
            "id": "2",
            "name": "External Card",
        },
    ];

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': '',
        }
    });

    useEffect(() => {
        setValue('externalbank_account', selectedExternalAccounts.length == 1 ? selectedExternalAccounts[0]?.nickname : '')
    }, [selectedExternalAccounts])

    const [selectedProductId, setSelectedProductId] = useState(
        getValues().productId
    );

    const navigateToExternalAccount = () => {
        if (externalAccounts.length > 0) {
            navigation.push('MoveMoney.externalAccountList', { externalAccounts, accounts, selectedExternalAccounts, screenName: 'MoveMoney.WithDrawFundDetail' })
        }
        else {
            navigation.push('MoveMoney.linkExternalAccount', { externalAccounts })
        }
    }

    const navigateToExternalCard = () => {
        if (externalAccounts.length > 0) {
            navigation.push('MoveMoney.ExternalCardList', { externalAccounts, accounts, selectedExternalAccounts, screenName: 'MoveMoney.WithDrawFundDetail' })
        }
        else {
            navigation.push('MoveMoney.linkExternalCard',{screenName: 'MoveMoney.WithDrawFundDetail' } /*, { externalAccounts } */)
        }
    }

    const onSubmit = (formData) => {
        toast.hideAll();
        const data = {
            toAccount: selectedExternalAccounts ? selectedExternalAccounts[0] : '',
            fromAccount: formData.checking_account
                ? accounts.find((row) => row.id === formData.checking_account)
                : '',
            amount: formData.amount,
            when: startDate,
        };
        if (selectedExternalAccounts[0]) {
            navigation.push('MoveMoney.WithDrawFundReadyTransfer', { data });
        }
        else {
            toast.show(i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.inValidEncounter'), {
                type: 'dangerWithTitle',
            });
        }

    };

    return (
        <View style={{ flex: 1 }}>

            <Controller
                control={control}
                name="checking_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts?.map((account) => ({
                            id: account.id,
                            name:
                                account.account_type +
                                ' ' +
                                account.account_number,
                        }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.from')}
                        errorMessage={
                            errors.checking_account && errors.checking_account.message
                        }
                        placeholder="Select account"
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <View style={{ display: 'flex', flexDirection: 'column' }}>
                <Text
                    style={{
                        fontSize: 17,
                        fontFamily: 'DMSans_400Regular',
                        color: 'rgba(0, 0, 0, 0.6)',
                        marginBottom: 5
                    }}
                >
                    {i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.to')}
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    {products?.map((item, index) => (
                        <View key={item.id} style={{ flexGrow: 1, flexShrink: 0, flexBasis: '0%', marginRight: index == 0 ? 10 : 0 }}>
                            <SendMoneyOutAccount
                                key={item.id}
                                product={item}
                                selected={item.id === selectedProductId}
                                onSelect={(productId) => {
                                    if (selectedProductId == productId) {
                                        setSelectedProductId(null);
                                    } else {
                                        setSelectedProductId(productId);
                                    }
                                    setValue('productId', productId);
                                }}
                            />
                        </View>
                    ))}
                </View>

                {selectedProductId == "1" &&
                    <>
                        <InformationDetail message={i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.informationAccount')} />
                        <Controller
                            control={control}
                            name="externalbank_account"
                            rules={{
                                required: {
                                    value: 'true',
                                    message: i18n.t('forms.common.required'),
                                },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    ref={accountInputRef}
                                    errorMessage={
                                        errors.externalbank_account && errors.externalbank_account.message
                                    }
                                    placeholder={i18n.t('common.selectExternalAccount')}
                                    onFocus={() => {
                                        accountInputRef.current.blur()
                                        navigateToExternalAccount();
                                        return
                                    }}
                                    onChangeText={onChange}
                                    value={value}
                                    style={webViewDesignSupport}
                                    rightIcon={{
                                        name: 'keyboard-arrow-right',
                                        onPress: () => { navigateToExternalAccount(); },
                                    }}
                                />
                            )}
                        />
                    </>
                }

                {selectedProductId == "2" &&
                    <>
                        <InformationDetail message={i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.informationCard')} />
                        <Controller
                            control={control}
                            name="externalcard"
                            // rules={{
                            //     required: {
                            //         value: 'true',
                            //         message: i18n.t('forms.common.required'),
                            //     },
                            // }}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    ref={cardInputRef}
                                    errorMessage={
                                        errors.externalcard && errors.externalcard.message
                                    }
                                    placeholder="Select external card"
                                    onFocus={() => {
                                        cardInputRef?.current?.blur()
                                        navigateToExternalCard();
                                        return
                                    }}
                                    onChangeText={onChange}
                                    value={value}
                                    style={webViewDesignSupport}
                                    rightIcon={{
                                        name: 'keyboard-arrow-right',
                                        onPress: () => navigateToExternalCard(),
                                    }}
                                />
                            )}
                        />
                    </>
                }
            </View>

            <Controller
                control={control}
                name="amount"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(\d+)(\.?)(\d){0,2}$/,
                        message: i18n.t(
                            'pages.moveMoney.withdrawFund.withdrawFundDetails.form.invalidAmount'
                        ),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <Input
                        errorMessage={errors.amount && errors.amount.message}
                        errorStyle={
                            theme.ReactNativeElementsTheme.Input.errorStyle
                        }
                        label={i18n.t('pages.moveMoney.withdrawFund.withdrawFundDetails.form.amount')}
                        textContentType="telephoneNumber"
                        dataDetectorTypes="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder={'$ 0.00'}
                        onChangeText={onChange}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            {isWeb() ?
                <WebDatePicker
                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                    date={startDate}
                    onChange={(selectedDate) => setStartDate(selectedDate)}
                /> :
                <DatePickerNative
                    label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.when')}
                    date={new Date(startDate)}
                    onChange={setStartDate}
                    minimumDate={addDays(new Date(),1)}
                />
            }

            {(selectedProductId == "1" || selectedProductId == "2") && (height < 700) ?
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
                :
                <View
                    style={{
                        bottom: 20,
                        position: isWeb() ? 'fixed' : 'absolute',
                        width: width - 50
                    }}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        title={i18n.t('forms.common.continue')}
                        onPress={handleSubmit(onSubmit)}
                    />
                </View>
            }
        </View >
    );
};

SendMoneyForm.propTypes = {
    externalAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            nickname: PropTypes.string,
            routing_identifiers: PropTypes.shape({
                bank_name: PropTypes.string,
                number: PropTypes.string,
            }),
        })
    ),
    account: PropTypes.shape({
        id: PropTypes.string,
        account_type: PropTypes.string,
        account_number: PropTypes.string,
    }),
    accounts: PropTypes.array,
};
