import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { View } from 'react-native';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';

export const AccountTile = ({ account, onPress }) => {
    const accountBalance = account?.balances?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );

    return (
        <TouchableOpacity
            disabled={!onPress}
            onPress={() => onPress && onPress()}
            style={{
                height: 165,
                width: '90%',
                marginLeft: 20,
                marginRight: 20,
                borderRadius: 16,
                ...theme.shadows.lightBlue,
            }}
        >
            <View
                style={{
                    borderRadius: 10,
                    overflow: 'hidden',
                    color: 'white',
                    flex: 1,
                }}
            >
                <LinearGradient
                    {...theme.gradients.lightBlue}
                    style={{
                        padding: 18,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 10,
                        background: '#FFFFFF',
                        boxShadow: '0px 0px 0px 1px #E0E0E0',
                    }}
                >
                    <View style={{ alignSelf: 'flex-start', flex: 1 }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        fontSize: 17,
                                        fontFamily: 'DMSans_700Bold',
                                        lineHeight: 20,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        textTransform: 'capitalize',
                                        alignItems: 'center',
                                    }}
                                >
                                    {i18n.t(
                                        `pages.accountSelection.accountType.${account.account_type}`
                                    )}
                                </Text>
                            </View>
                        </View>

                        <Text
                            style={{
                                marginTop: 22,
                                marginBottom: 10,
                                fontFamily: 'DMSans_400Regular',
                                fontSize: 12,
                                lineHeight: 20,
                                letterSpacing: 0.4,
                                color: 'rgba(0, 0, 0, 0.6)',
                            }}
                        >
                            {i18n.t('pages.home.balance')}
                        </Text>
                        <TextGradient
                            style={{
                                fontSize: 24,
                                marginTop: 'auto',
                                alignSelf: 'flex-start',
                                ...theme.typography.mediumFontWeight,
                            }}
                        >
                            <TextCurrency
                                value={
                                    accountBalance &&
                                    accountBalance.balance / 100
                                }
                            />
                            <View>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        marginLeft: 10,
                                    }}
                                >
                                    {account.currency}
                                </Text>
                            </View>
                        </TextGradient>
                    </View>
                </LinearGradient>
            </View>
        </TouchableOpacity>
    );
};
AccountTile.propTypes = {
    account: PropTypes.shape({
        nickname: PropTypes.string,
        id: PropTypes.string,
        account_number: PropTypes.string,
        account_type: PropTypes.string,
        currency: PropTypes.string,
        balances: PropTypes.array,
    }),
    onPress: PropTypes.func,
};
