import { userState, authState } from "../store/auth0/slice";
import { useAppDispatch } from "./store";
import Auth0, { useAuth0 } from 'react-native-auth0';
import Constants from 'expo-constants';
import { clearItemAsync, getItemAsync, setItemAsync } from "../common/utils/secure-store.utils";
import { AuthStateEnum, api, log } from "../../App.context";
import jwt_decode from 'jwt-decode';
import moment from 'moment';

const auth0 = new Auth0({
    domain: Constants?.manifest?.extra?.auth0_domain,
    clientId: Constants?.manifest?.extra?.auth0_clientId,
});
export const useAuth0Actions = () => {
	const dispatch = useAppDispatch();
    const { clearSession } = useAuth0();

    const setSession = async ({ authDetails, shouldReload }) => {
        await setItemAsync(
            'authDetails',
            JSON.stringify({   
                accessToken: authDetails.accessToken,
                refreshToken: authDetails.refreshToken,
            })
        );
        log.debug('Auth0 - Access Token', authDetails.accessToken);
        api.jwt(authDetails.accessToken);

        const customerId = jwt_decode(authDetails.accessToken)[
            'https://synctera/customerId'
        ];

        if (shouldReload) {
            dispatch(authState(customerId
                    ? AuthStateEnum.Customer_Active
                    : AuthStateEnum.Customer_Pending_Onboarding))
            const userInfo = await auth0.auth.userInfo({
                token: authDetails.accessToken,
            });
            log.debug('Auth0 - User Info', userInfo);
            dispatch(userState(userInfo))
        }
    }
    
	const signIn = ({ email, password } : {email: string, password: string}) => {
        return auth0.auth.passwordRealm({   
            username: email,
            password,
            realm: Constants?.manifest?.extra?.auth0_realm,
            audience: Constants?.manifest?.extra?.auth0_audience,
            scope: 'openid profile email offline_access',
	    }).then((authDetails) =>
       { setSession({ authDetails, shouldReload: true })}
    );
    }

    const resumeSession = async () => {
        // await clearItemAsync('authDetails');
        const authDetailsString = await getItemAsync('authDetails');

        if (authDetailsString) {
            const authDetails = JSON.parse(authDetailsString);
            const exp = moment.unix(jwt_decode(authDetails.accessToken).exp);
            const now = moment();

            if (now.isAfter(exp)) {
                await refreshSession({ shouldReload: true });
            } else {
                await setSession({ authDetails, shouldReload: true });
            }
        }
    }

    const refreshSession = async({ shouldReload }: {shouldReload: boolean}) => {
        const currentAuthDetails = await getItemAsync('authDetails');
        const refreshToken = JSON.parse(currentAuthDetails).refreshToken;
        const newAuthDetails = await auth0.auth.refreshToken({ refreshToken });
        await setSession({
            authDetails: { ...newAuthDetails, refreshToken },
            shouldReload,
        });
    }

    const forgotPassword = ({ email }) => {
        return auth0.auth.resetPassword({
            connection: Constants?.manifest?.extra?.auth0_realm,
            email: email,
        });
    }

    const signUp = async ({ email, password }) => {
        return auth0.auth
            .createUser({
                connection: Constants?.manifest?.extra?.auth0_realm,
                email: email,
                password: password,
            })
            .then(() => signIn({ email, password }));
    }

    const signOut = async() => {
        // Revoke token
        try {
            const currentAuthDetails = await getItemAsync('authDetails');
            const refreshToken = JSON.parse(currentAuthDetails).refreshToken;
            await auth0.auth.revoke({ refreshToken }).catch(console.log);

            // Forget saved tokens
            await clearItemAsync('authDetails');

            // Reset states
            api.jwt(null);
            dispatch(authState(AuthStateEnum.Guest));
            dispatch(userState({}))
            await clearSession();
        } catch (e) {
            console.log(e);
        }
    }

	return { signIn, resumeSession, refreshSession, forgotPassword, signUp, signOut };
};