import React from 'react';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import { useNavigation } from '@react-navigation/native';

export const SignupMemberLink = () => {
    const navigation = useNavigation();
    return (
        <TouchableOpacity
            style={{
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: 50,
                outlineStyle: isWeb() ? 'none' : null,
            }}
            onPress={() => navigation.navigate('Auth.SignUp')}
        >
            <Text
                style={{
                    fontFamily: 'DMSans_400Regular',
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: 16,
                    lineHeight: 24,
                    textAlign: 'center',
                    letterSpacing: 0.15,
                }}
            >
                {i18n.t('common.signupText')}&nbsp;
            </Text>
            <Text
                style={{
                    fontFamily: 'DMSans_500Medium',
                    color: '#00008E',
                    fontSize: 14,
                    lineHeight: 24,
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                }}
            >
                {i18n.t('common.signup')}
            </Text>
        </TouchableOpacity>
    );
};
