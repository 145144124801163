import { Button } from 'react-native-elements';
import { MainContext, api } from '../../../App.context';
import { View, useWindowDimensions } from 'react-native';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import React from 'react';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import theme from '../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { profileState } from '../../store/auth0/slice';

export const VerifyIdentityModal = () => {
    const navigation = useNavigation();
    const toast = useToast();
    const { width } = useWindowDimensions();
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => state.users);

    const verifyIdentity = () => {
        toast.hideAll();
        api.post('/verifications', {})
            .then((verification) => {
                const isSuccess = verification.result === 'ACCEPTED';
                const toastType = isSuccess
                    ? 'successWithTitle'
                    : 'dangerWithTitle';
                const toastTitle = isSuccess
                    ? i18n.t('pages.kycInReview.verificationSuccessTitle')
                    : i18n.t('pages.kycInReview.verificationFailureTitle');
                const toastMessage = isSuccess
                    ? i18n.t('pages.kycInReview.verificationSuccessMessage')
                    : i18n.t('pages.kycInReview.verificationFailureMessage');
                    dispatch(profileState({
                        ...profile,
                        kyc_status: verification.result,
                    }))
                
                navigation.goBack();
                toast.show(toastMessage, {
                    type: toastType,
                    data: {
                        title: toastTitle,
                    },
                });
            })
            .catch((error) => {
                navigation.goBack();
                toast.show(error.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    return (
        <ModalLayout
            subtitle={
                i18n.t('pages.kycInReview.explanation') +
                '\n\n' +
                i18n.t('pages.kycInReview.instructions')
            }
        >
            <View
                style={{
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <View
                    style={{
                        bottom: 20,
                        position: isWeb() ? 'fixed' : 'absolute',
                        width: width - 50,
                        marginTop: 30,
                        marginBottom: 15,
                    }}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        loadingProps={{
                            color: theme.colors.textHeading,
                        }}
                        title={i18n.t('pages.kycInReview.verifyNow')}
                        onPress={verifyIdentity}
                    />
                </View>
            </View>
        </ModalLayout>
    );
};
