import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../App.context';
import { View, useWindowDimensions } from 'react-native';
import React from 'react';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import theme from '../../common/ui/theme';
import { useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { useAppDispatch } from '../../hooks/store';
import { profileState } from '../../store/auth0/slice';

export const EditEmailScreen = () => {
    const { width } = useWindowDimensions();
    const {
        params: { profile },
    } = useRoute();

    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({ defaultValues: { email: profile.email } });

    const toast = useToast();

    const focusInput = true;
    const dispatch = useAppDispatch();

    const onSubmit = (formData) => {
        toast.hideAll();
        const body = {
            email: formData.email,
        };

        return api
            .patch('/customer', { body })
            .then((response) => {
                dispatch(profileState(response))
                toast.show(i18n.t('pages.profile.editEmail.toast.success'), {
                    type: 'successWithTitle',
                });
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    return (
        <ScrollableScreenLayout>
            <View style={{ flex: 1 }}>
                <Controller
                    control={control}
                    name="email"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                        pattern: {
                            value: /^(.+)@(.+)\.(.{2,6})$/,
                            message: i18n.t('forms.email.invalidFormat'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            errorMessage={
                                focusInput &&
                                errors.email &&
                                errors.email.message
                            }
                            keyboardType="email-address"
                            label={i18n.t('forms.email.label')}
                            placeholder={i18n.t('forms.email.placeholder')}
                            autoCapitalize="none"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                            autoFocus={true}
                        />
                    )}
                />
                <View
                    style={isWeb() ? {
                        bottom: 20,
                        position: 'fixed',
                        width: width - 50,
                    } : null}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        disabled={!isValid}
                        title={i18n.t('forms.common.save')}
                        onPress={handleSubmit(onSubmit)}
                    />
                </View>
            </View>
        </ScrollableScreenLayout>
    );
};
