import { AuthStateEnum } from '../../App.context';
import { KYCFailureScreen } from '../screens/kyc/kyc-failure.screen';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useAppSelector } from '../hooks/store';

const Stack = createNativeStackNavigator();

export const FailedKYCNavigator = () => {

    const {authState, profile} = useAppSelector((state) => state.users)
    const shouldRender =
        authState === AuthStateEnum.Customer_Active &&
        profile.kyc_status === 'REJECTED';

    return (
        shouldRender && (
            <Stack.Navigator
                initialRouteName="KYC.Failure"
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen
                    name="KYC.Failure"
                    options={{ headerShown: false }}
                    component={KYCFailureScreen}
                />
            </Stack.Navigator>
        )
    );
};
